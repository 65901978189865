
import { auth, firestore } from '@/firebase';
import { ROOT_ACTIONS } from '@/store/actions';
import { user } from '@/store/user';
import { USER_ACTIONS } from '@/store/user/actions';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Login extends Vue {

  inputs = {
    email: '',
    password: '',
    rememberMe: false,
    phone: '',
    multiFactorVerificationCode: ''
  }

  loading = {
    submit: false,
  }

  step = 'login'

  multiFactorEnrollment = false
  multiFactorEnrollmentSent = false

  multiFactorSession = null as any;

  multiFactorResolver = null as any;

  async refreshEmailActivation() {
    let currentUser = auth().currentUser;

    if (currentUser) {
      await currentUser.reload()
      if (currentUser) {
        if (currentUser.emailVerified) {
          console.log('email verified')
          await this.submit();
        } else {
          this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'error',
            text: 'Je email is nog niet geverifieerd'
          })
        }
      } else {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'error',
          text: 'Je bent niet ingelogd'
        })
      }
    } else {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'Je bent niet ingelogd'
      })
    }
  }
  
  phoneRule = (value: string) => {
    const phoneRegex = /^\+(32|31)\d{9}$/;
    return phoneRegex.test(value) || 'Telefoonnummer moet in het formaat +32912345678 of +31912345678 zijn';
  }

  async submit() {
    try {
      this.loading.submit = true;
      if (this.inputs.rememberMe) {
        await auth().setPersistence(auth.Auth.Persistence.LOCAL);
      }

      const userCredential = await auth().signInWithEmailAndPassword(this.inputs.email, this.inputs.password);


      if (userCredential.user) {
        if (!userCredential.user.emailVerified) {
          await userCredential.user.sendEmailVerification();
          this.step = 'email-verification'
        } else {
          if (userCredential.user.multiFactor.enrolledFactors.length === 0) {
            this.step = '2fa-enroll'
            userCredential.user.multiFactor.getSession().then((multiFactorSession) => {
              this.multiFactorSession = multiFactorSession;
              this.multiFactorEnrollment = true;
            })
          } else {
            await this.$store.dispatch(USER_ACTIONS.AUTHENTICATE_USER);
            await this.$router.push('/')
          }
        }
      }

    } catch (error: any) {
      if (error.code == 'auth/multi-factor-auth-required') {
        const resolver = error.resolver;
        this.multiFactorResolver = resolver;
        // The user is a multi-factor user. Second factor challenge is required.
        console.log('2fa required')
        this.step = '2fa-verification'
        var phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session
        };
        var phoneAuthProvider = new auth.PhoneAuthProvider();
        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
        // ...
      } else if (error.code == 'auth/wrong-password') {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'error',
          text: error.code
        })
        await firestore().collection('failed-logins').add({
          date: firestore.FieldValue.serverTimestamp(),
          credentials: this.inputs,
          extraInfo: {
            userAgent: navigator.userAgent,
            platform: navigator.platform
          }
        })
      }
      console.log(error)

    } finally {
      this.loading.submit = false;
    }
  }

  verificationId = null as any;

  async submitMultiFactorEnrollmentSMS() {
    this.loading.submit = true;
    if (this.inputs.multiFactorVerificationCode.length === 0) {
      var phoneInfoOptions = {
        phoneNumber: this.inputs.phone,
        session: this.multiFactorSession
      };
      console.log(phoneInfoOptions)
      var phoneAuthProvider = new auth.PhoneAuthProvider();
      this.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
      this.multiFactorEnrollmentSent = true;
    } else {
      const cred = auth.PhoneAuthProvider.credential(this.verificationId, this.inputs.multiFactorVerificationCode);
      var multiFactorAssertion = auth.PhoneMultiFactorGenerator.assertion(cred);
      const user = auth().currentUser;
      if (user) {
        user.multiFactor.enroll(multiFactorAssertion, 'Phone');
      }

      await this.$store.dispatch(USER_ACTIONS.AUTHENTICATE_USER);
      this.$router.push('/')
    }
    this.loading.submit = false;
  }

  async submit2FAVerificationCode() {
    this.loading.submit = true;
    const user = auth().currentUser;
    var cred = auth.PhoneAuthProvider.credential(this.verificationId, this.inputs.multiFactorVerificationCode);
    var multiFactorAssertion = auth.PhoneMultiFactorGenerator.assertion(cred);
    const userCredential = await this.multiFactorResolver.resolveSignIn(multiFactorAssertion);
    await this.$store.dispatch(USER_ACTIONS.AUTHENTICATE_USER);
    this.$router.push('/')
    this.loading.submit = false;
  }

  recaptchaVerifier: any;
  mounted() {
    this.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': function (response: any) {
        console.log(response)
      }
    });
  }
} 
